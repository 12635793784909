import React from "react";
import { useGoogleLogin } from '@react-oauth/google';
import {Box,Button, IconButton,Typography } from "@mui/material"
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';

interface GoogleAuthButtonProps {
  onLoginSuccess: (token: string) => Promise<void>;  // 非同期関数として定義
    isRegister?: boolean;  // 新規登録かどうかを判定するためのプロパティ
  }

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ onLoginSuccess, isRegister=false }) => {
    const navigate = useNavigate();

    const googleLogin = useGoogleLogin({
      onSuccess: async (response) => {
        try {
          await onLoginSuccess(response.access_token);  // onLoginSuccess完了後に遷移
          navigate('/');  // ログイン・登録成功時に遷移
        } catch (error) {
          console.error('Google login handling failed', error);
        }
      },
      onError: () => console.error('Google login failed'),
    });

    return(
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={() => googleLogin()}
                sx={{ marginBottom: 2, width: "100%", maxWidth: 300 }}
            >
                {isRegister ? "Googleアカウントで新規登録" : "Googleアカウントでログイン"}
            </Button>
    </Box>
        );
};

export default GoogleAuthButton