import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import { Box,TextField, Button, Typography, Container, IconButton, InputAdornment } from '@mui/material'; // MUIから必要なコンポーネントをインポート
import { Visibility, VisibilityOff, CheckCircle } from '@mui/icons-material'; // アイコン用のインポート
import GoogleAuthButton from "./GoogleAuthButton";

interface RegisterFormProps {
    onRegister: (email: string, password: string) => Promise<boolean>;
    onGoogleRegister: (token: string) => Promise<void>;  // 非同期関数に変更
  }

const Register: React.FC< RegisterFormProps > = ({onRegister, onGoogleRegister}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState<string[]>([]);
    const [showPassword, setShowPassword] = useState(false); // パスワード表示切り替え
    const [isPasswordValid, setIsPasswordValid] = useState(false); // パスワード条件を満たしたか

    // パスワード条件チェック
  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    return lengthValid && hasNumber && hasSymbol && hasLetter;
};
const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setPassword(password); // 既存のonChange処理
    setIsPasswordValid(validatePassword(password)); // パスワードのバリデーション処理
  };

    const navigate = useNavigate();

    // メールアドレスとパスワードによる新規登録処理
  const handleRegister = async () => {
    if (email && password) {
      try {
        const isSuccess = await onRegister(email, password); // 新規登録処理
        if (isSuccess) {
          navigate('/');  // 登録成功時にのみ遷移
        } else {
          setErrorMessage(["新規登録に失敗しました"]);
        }
      } catch (error) {
        console.error("Registration failed:", error);
        setErrorMessage(["新規登録に失敗しました"]);
      }
    } else {
      setErrorMessage(["メールアドレスとパスワードを入力してください"]);
    }
  };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h4" gutterBottom>新規登録</Typography>
            {errorMessage.length > 0 && (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '16px', width: '100%', maxWidth: 450, whiteSpace: 'pre-line', color:'red'}}>
         {errorMessage.map((msg, index) => (
            <React.Fragment key={index}>
              {msg}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      )}
            <TextField
                label="メールアドレス"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  width: '100%',  // 幅を100%に設定
                  maxWidth: 450,
                  margin: '0 auto'  // 左右中央寄せ
              }}
            />
            <TextField
                label="パスワード"
                variant="outlined"
                margin="normal"
                type={showPassword ? 'text' : 'password'} // パスワード表示切り替え
                value={password}
                onChange={handlePasswordChange}
                sx={{
                  width: '100%',  // 幅を100%に設定
                  maxWidth: 450,
                  margin: '5 auto 0'  // 左右中央寄せ
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    {isPasswordValid && <CheckCircle sx={{ color: 'green', ml: 1 }} />} {/* パスワードが有効ならチェック表示 */}
                  </InputAdornment>
                ),
              }}
            />
            <Typography gutterBottom sx={{
                  width: '100%',  // 幅を100%に設定
                  maxWidth: 450,
                  margin: '5 auto 0'  // 左右中央寄せ
              }}>
        パスワードは半角英字、数字、記号を組み合わせた8文字以上で入力してください。
      </Typography>
            
            <Button
                variant="contained"
                color="primary"
                onClick={handleRegister}
                sx={{ marginTop: 2 }}
                disabled={!isPasswordValid}
            >
                登録
            </Button>
            <Typography variant="h6" sx={{ marginTop: 3, marginBottom: 3 }}>または</Typography>
            <GoogleAuthButton onLoginSuccess={onGoogleRegister} isRegister={true}/>
        </Box>
    );
};

export default Register;