import React from "react";
import { Typography, Box, Link, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      py={3}
      textAlign="center"
      width="100%"
      bgcolor={theme.palette.grey[200]} // 背景色を薄いグレーに設定
      sx={{
        mt: 4,
        px: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        © 2024 Cut Edge by Tokimoa. All rights reserved.
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        sx={{
          gap: 2,
          fontSize: "0.9rem",
          "& a": {
            textDecoration: "none",
            color: theme.palette.text.secondary,
            "&:hover": {
              color: theme.palette.primary.main,
            },
          },
        }}
      >
        <Link
      href="https://tokimoa.jp"
      underline="none"
      target="_blank"
    >
      運営
    </Link>
    <Typography variant="body2" color="textSecondary">
          |
        </Typography>
        <Link
          href="https://tokimoa.jp/information/privacy/"
          underline="none"
          target="_blank"
        >
          プライバシーポリシー
        </Link>
        <Typography variant="body2" color="textSecondary">
          |
        </Typography>
        <Link
          href="https://tokimoa.jp/terms_of_service/"
          underline="none"
          target="_blank"
        >
          利用規約
        </Link>
        <Typography variant="body2" color="textSecondary">
          |
        </Typography>
        <Link
          href="https://tokimoa.jp/contact/"
          underline="none"
          target="_blank"
        >
          お問い合わせ
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
