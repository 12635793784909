import React, { useState } from 'react';
import { AppBar, Toolbar, Typography,Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import logo from "../logo.svg";

interface HeaderProps {
    onMenuClick: () => void;
    isLoggedIn: boolean;
    userName: string;
    onLoginClick: () => void;      
    onRegisterClick: () => void;   
    handleLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isLoggedIn,
  userName,
  onLoginClick,          
  onRegisterClick,      
  onMenuClick,
  handleLogout,
  
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate(); // useNavigateフックを使用

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();  // イベント伝播を防ぐ
        setAnchorEl(event.currentTarget); // Avatar押下でメニュー展開
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();  // イベント伝播を防ぐ
        setAnchorEl(null); // メニューを閉じる
    };
    const handleCutlyClick = () => {
        navigate('/'); // Cutly押下時にメインページへ
    };

    const handleLoginClick = () => {
        navigate('/select-login'); // ログイン選択ページへ遷移
    };

    const handleRegisterClick = () => {
        navigate('/select-register'); // 新規登録選択ページへ遷移
    };

    // ログアウト時にメニューを閉じてから handleLogout を実行
    const handleLogoutAndCloseMenu = () => {
        setAnchorEl(null);  // メニューを閉じる
        handleLogout();     // ログアウト処理
    };

    return (
        <AppBar position="static">
            <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  {/* ロゴとテキストを横並びに配置 */}
  <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={handleCutlyClick} // ホームに遷移するクリックイベント
    >
      <Box
        component="img"
        src={logo}
        alt="Cut Edge Logo"
        sx={{
          width: 40,
          height: 40,
          mr: 1, // ロゴとテキスト間に間隔を追加
        }}
      />
      <Typography variant="h6" color="inherit">
        CutEdge
      </Typography>
    </Box>
  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
    {isLoggedIn ? (
      <>
        <IconButton onClick={handleAvatarClick} color="inherit">
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate('/subscription')}>サブスクリプション管理</MenuItem>
          <MenuItem onClick={handleLogoutAndCloseMenu}>ログアウト</MenuItem>
        </Menu>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onMenuClick}
          sx={{ display: { xs: 'block', sm: 'none' }, ml: 'auto' }}
        >
          <MenuIcon />
        </IconButton>
      </>
    ) : (
      <>
        <Button
          variant="text"
          sx={{ color: "white", border: "none" }}
          onClick={handleLoginClick}
        >
          ログイン
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: "white",
            backgroundColor: "secondary.main",
            borderColor: "secondary.main",
            '&:hover': {
              backgroundColor: "secondary.dark",
              borderColor: "secondary.dark",
            },
          }}
          onClick={handleRegisterClick}
        >
          新規登録
        </Button>
      </>
    )}
  </Box>
</Toolbar>

        </AppBar>
    );
};

export default Header;
