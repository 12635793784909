import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

interface SelectLoginMethodProps {
    onFormSelect: () => void;
    onGoogleSelect: (token: string) => void;
    isRegister: boolean; // ログインか新規登録かを制御
}

const SelectLoginMethod: React.FC<SelectLoginMethodProps> = ({onFormSelect, onGoogleSelect, isRegister}) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');

    // Googleログイン処理
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        console.log("Google login successful via Method");
        await onGoogleSelect(response.access_token); // トークン処理
        navigate('/'); // ログイン成功後にホームページへ遷移
      } catch (error) {
        console.error("Google login failed:", error);
        setErrorMessage("Googleログインに失敗しました");
      }
    },
    onError: () => {
      console.error('Google login failed');
      setErrorMessage("Googleログインに失敗しました");
    },
  });

    const handleEmailClick = () => {
        onFormSelect(); // 状態を変更してフォームの表示を制御
        if (isRegister) {
            navigate('/register'); // 新規登録ページに遷移
        } else {
            navigate('/login'); // ログインページに遷移
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h5" gutterBottom>
                {isRegister ? "新規登録方法を選択" : "ログイン方法を選択"}
            </Typography>
            <Button
             variant="outlined"
             startIcon={<EmailIcon />}
             onClick={handleEmailClick}
             sx={{ marginBottom: 2, width: "100%", maxWidth: 300 }}
             >
                { isRegister ? "メールアドレスで新規登録" : "メールアドレスでログイン"}
             </Button>
             <Button
             variant="outlined"
             startIcon={<GoogleIcon />}
             onClick={() => googleLogin()}
             sx={{ width: "100%", maxWidth: 300 }}
             >
                {isRegister ? "Googleアカウントで新規登録" : "Googleアカウントでログイン"}
             </Button>
        </Box>
    );
};

export default SelectLoginMethod;