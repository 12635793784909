import React, { useState } from "react";

import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { apiRequestWithAuth } from "../utils/auth";

const Upload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || "").trim();

  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "video/mp4") {
      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
    } else {
      alert("MP4ファイルのみアップロード可能です");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);

    const formData = new FormData();
    formData.append("video", selectedFile);

    const response = await apiRequestWithAuth(
      `${BASE_URL}/api/v1/videos/upload/`,
      {
        method: "POST",
        body: formData, // フォームデータをボディとして送信
      },
      navigate
    );

    setIsUploading(false);

    if (response?.error) {
      if (response.error === "アップロード制限に達しました") {
        console.log(response);
        // 制限超過時
        alert(`${response.error} (${response.time_until_reset})`);
      } else {
        // その他のエラー
        alert("アップロードに失敗しました。時間をおいて再度お試しください。");
      }
    } else if (response) {
      // 成功時
      alert(
        "アップロードが完了しました。カット表が出来上がるまでお待ち下さい。"
      );
      navigate("/dashboard");
    }
  };

  return (
    <Card sx={{ maxWidth: 600, mx: "auto", mt: 4, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" gutterBottom>
            動画をアップロード
          </Typography>
          <TextField
            type="file"
            inputProps={{ accept: "video/mp4" }}
            onChange={handleFileChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            ※ ファイル名は半角英数字のみ対応しています。
          </Typography>
          {preview && (
            <Box sx={{ width: "100%", maxWidth: 500, mb: 2 }}>
              <video
                muted
                src={preview}
                controls
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          )}
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!selectedFile || isUploading}
            sx={{ width: "100%", maxWidth: 300 }}
          >
            {isUploading ? <CircularProgress size={24} /> : "アップロード"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Upload;
