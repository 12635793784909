import React, { createContext, useContext } from "react";

// Context の型を定義
interface UserPlanContextType {
  isPaidUser: boolean;
}

// デフォルト値を設定
const UserPlanContext = createContext<UserPlanContextType | undefined>(undefined);

// Provider コンポーネントを作成
export const UserPlanProvider: React.FC<{ isPaidUser: boolean; children: React.ReactNode }> = ({ isPaidUser, children }) => (
  <UserPlanContext.Provider value={{ isPaidUser }}>
    {children}
  </UserPlanContext.Provider>
);

// Context を使うためのカスタムフック
export const useUserPlan = () => {
  const context = useContext(UserPlanContext);
  if (!context) {
    throw new Error("useUserPlan must be used within a UserPlanProvider");
  }
  return context;
};
