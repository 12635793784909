import { fetchUserInfo } from "./auth";

const BASE_URL = (process.env.REACT_APP_API_BASE_URL || '').trim();
export const handleLogin = async (
    email: string,
    password: string,
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/auth/email_login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include'
      });
  
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        setShowLogin(false);
        const userInfo = await fetchUserInfo(); // fetchUserInfo を呼び出し
        if (userInfo) {
          setUserName(userInfo.username); // 必要に応じて状態を更新
        }
        return true;
      } else {
        const data = await response.json();
        console.error('Login failed', data);
        setErrorMessage(data.error || 'Login failed');
        return false;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setErrorMessage('An error occurred during login');
      return false;
    }
  };
  
  export const handleRegister = async (
    email: string,
    password: string,
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setShowRegister: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  ): Promise<boolean> => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/auth/email_register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include'
      });
  
      if (response.ok) {
        const data = await response.json();
        setIsLoggedIn(true);
        setShowRegister(false);
        const userInfo = await fetchUserInfo(); // fetchUserInfo を呼び出し
        if (userInfo) {
          setUserName(userInfo.username); // 必要に応じて状態を更新
        }
        return true;
      } else {
        const data = await response.json();
        console.error('Register failed', data.error);
        setErrorMessage(data.error || 'Registration failed');
        return false;
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setErrorMessage('An error occurred during registration');
      return false;
    }
  };
  
  export const handleGoogleLogin = async (
    token: string,
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>,
    fetchUserInfo: () => Promise<void>
  ) => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/auth/google/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: token }),
        credentials: 'include'
      });
  
      if (response.ok) {
        setIsLoggedIn(true);
        setShowLogin(false);
        await fetchUserInfo(); // fetchUserInfo を呼び出し
      } else {
        console.error('Google login failed');
      }
    } catch (error) {
      console.error('An error occurred during Google login:', error);
    }
  };
  
  export const handleGoogleRegister = async (
    token: string,
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setShowLogin: React.Dispatch<React.SetStateAction<boolean>>,
    fetchUserInfo: () => Promise<void>
  ) => {
    try {
      const response = await fetch(`${BASE_URL}/api/v1/auth/google/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ access_token: token }),
        credentials: 'include'
      });
  
      if (response.ok) {
        setIsLoggedIn(true);
        setShowLogin(false);
        await fetchUserInfo();
      } else {
        console.error('Google registration failed');
      }
    } catch (error) {
      console.error('An error occurred during Google registration:', error);
    }
  };
  