// src/utils/api.ts
const BASE_URL = (process.env.REACT_APP_API_BASE_URL || '').trim();

// クッキーから指定のクッキー値を取得する関数
const getLoginStateFromCookie = (name: string) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

// クッキーからCSRFトークンを取得する関数
const getCSRFFromCookie = (name: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return undefined;
};


// ログイン状態を確認する関数
const isLoggedIn = () => {
  return getLoginStateFromCookie('login') === 'true';
};

// ログアウト関数
/*
export const handleLogout = async (
  navigate: any,
  resetState: () => void  // 状態リセット関数を引数で受け取る
) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/auth/logout/`, {
      method: 'POST',
      credentials: 'include',  // クッキーをリクエストに含める
    });

    if (response.ok) {
      console.log('Logged out successfully');
      resetState();  // 状態をリセット
      navigate('/login');  // ログアウト後にログインページにリダイレクト
    } else {
      console.error('Failed to log out');
    }
  } catch (error) {
    console.error('An error occurred during logout:', error);
  }
};
*/
// ログアウト関数
export const handleLogout = async (
  navigate: any,
  resetState: () => void  // 状態リセット関数を引数で受け取る
) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/auth/logout/`, {
      method: 'POST',
      credentials: 'include',  // クッキーをリクエストに含める
    });
    if (response.ok) {
      console.log('Logged out successfully');
      // Cookieを削除
      document.cookie = 'access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; pat h=/;';
      document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      resetState();  // 状態をリセット
      navigate('/login'); // ログアウト後にログインページにリダイレクト
    } else {
      console.error('Failed to log out');
    }
  } catch (error) {
    console.error('An error occurred during logout:', error);
  }
};

/*
// トークンをリフレッシュする関数
export const refreshToken = async (navigate: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/auth/refresh/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      console.log('Token refreshed successfully');
      return true;  // リフレッシュ成功
    } else {
      console.error('Token refresh failed');
      //await handleLogout(navigate);  // ログアウトしてリダイレクト
      return false;
    }
  } catch (error) {
    console.error('An error occurred while refreshing token:', error);
    //await handleLogout(navigate);  // ログアウトしてリダイレクト
    return false;
  }
};*/

// トークンをリフレッシュする関数
export const refreshToken = async (navigate: any) => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/auth/refresh/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      console.log('Token refreshed successfully');
      return true; // リフレッシュ成功
    } else {
      console.error('Token refresh failed');
      await handleLogout(navigate, ()=>{}); // ログアウトしてリダイレクト
      return false;
    }
  } catch (error) {
    console.error('An error occurred while refreshing token:', error);
    await handleLogout(navigate, ()=>{});  // ログアウトしてリダイレクト
    return false;
  }
};
/*
// APIリクエスト用の関数（ログイン中に使用）
export const apiRequestWithAuth = async (url: string, options: RequestInit, navigate: any) => {
  const csrfToken = getCSRFFromCookie('csrftoken') || '';  // csrfTokenがundefinedの場合、空文字にフォールバック
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'X-CSRFToken': csrfToken,  // CSRFトークンをヘッダーに追加
      },
      credentials: 'include',
    });

    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) { // トークン失効のエラーハンドリング
      console.error('Unauthorized request. Please log in again.');
      navigate('/login');
      return null;
    } else {
      console.error('API request failed');
      return null;
    }
  } catch (error) {
    console.error('An error occurred during API request:', error);
    return null;
  }
};
*/
export const apiRequestWithAuth = async (
  url: string,
  options: RequestInit,
  navigate: any
): Promise<any> => {
  const csrfToken = getCSRFFromCookie('csrftoken') || '';  // csrfTokenがundefinedの場合、空文字にフォールバック
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'X-CSRFToken': csrfToken,  // CSRFトークンをヘッダーに追加
      },
      credentials: 'include',
    });
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) { // トークン失効のエラーハンドリング
      const refreshSucceeded = await refreshToken(navigate); // トークンリフレッシュを試行
      if (refreshSucceeded) {
        // リフレッシュ成功したら再試行
        return apiRequestWithAuth(url, options, navigate);
      } else {
        // リフレッシュ失敗したらログアウト
        console.error('Unauthorized request. Please log in again.');
        navigate('/login');
        return { error: 'ログインしてください', status: 401 };
      }
    } else {
      console.error('API request failed');
      // エラー応答をパースして返す
      let errorData;
      try {
        errorData = await response.json(); // サーバーからのエラー詳細をパース
      } catch (e) {
        errorData = { error: '不明なエラーが発生しました' }; // JSON以外の応答の場合
      }
      return {
        ...errorData,
        status: response.status, // ステータスコードを含める
      };
    }
  } catch (error) {
    console.error('An error occurred during API request:', error);
    return {
      error: 'ネットワークエラーが発生しました。再試行してください。',
      status: 0,
    };
  }
};

//ユーザー情報取得関数
export const fetchUserInfo = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/v1/user-info/`, {
      method: 'GET',
      credentials: 'include', // クッキーをリクエストに含める
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // 必要ならデータを返す
    } else {
      console.error('Failed to fetch user info');
      return null;
    }
  } catch (error) {
    console.error('An error occurred while fetching user info:', error);
    return null;
  }
};
