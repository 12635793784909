import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useNavigate } from "react-router-dom";
import { apiRequestWithAuth } from "../utils/auth";

interface Video {
  id: number;
  title: string;
  uploaded_at: string;
  file_path: string;
  processing_status: string;
}

const Dashboard: React.FC = () => {
  const [videos, setVideos] = useState<Video[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || "").trim();

  const handleDeleteClick = (video: Video) => {
    setSelectedVideo(video);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedVideo(null);
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (!selectedVideo) return;

    const response = await apiRequestWithAuth(
      `${BASE_URL}/api/v1/videos/delete/${selectedVideo.id}/`,
      { method: "DELETE" },
      navigate
    );

    if (response && !response.error) {
      // 削除成功時
      alert("動画が削除されました");
      setVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== selectedVideo.id)
      );
    } else {
      // 削除失敗時
      alert(response?.error || "動画の削除に失敗しました");
    }

    handleDialogClose();
  };

  useEffect(() => {
    const fetchVideos = async () => {
      const data = await apiRequestWithAuth(
        `${BASE_URL}/api/v1/videos/`,
        { method: "GET" },
        navigate
      );
      if (data) {
        setVideos(data);
      }
    };

    fetchVideos();
  }, [navigate]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflowX: "auto", // 横スクロールを有効にする
          "&::-webkit-scrollbar": {
            height: "8px", // スクロールバーの高さ
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc", // スクロールバーの色
            borderRadius: "4px", // 角を丸くする
          },
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150 }}>タイトル</TableCell>
              <TableCell sx={{ minWidth: 150 }}>アップロード日</TableCell>
              <TableCell sx={{ minWidth: 100 }}>
                操作{" "}
                <Tooltip
                  title="カット表が作成されるとボタンが有効になります"
                  placement="top"
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      fontSize: "0.8rem", // フォントサイズを小さく
                      padding: "4px 8px", // パディングを調整
                    },
                  }}
                >
                  <IconButton>
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((video: Video) => (
              <TableRow key={video.id}>
                <TableCell>{video.title}</TableCell>
                <TableCell>
                  {new Date(video.uploaded_at).toLocaleDateString("ja-JP")}
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap={1} // ボタン同士の間隔を指定
                  >
                    <Button
                      variant="contained"
                      disabled={video.processing_status !== "completed"} // completed でない場合は無効
                      onClick={() =>
                        navigate(`/cut-table/${video.id}`, {
                          state: { videoUrl: video.file_path },
                        })
                      }
                    >
                      カット表確認
                    </Button>

                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteClick(video)}
                    >
                      削除
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">動画の削除</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            「{selectedVideo?.title}
            」を削除してもよろしいですか？この操作は取り消せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            削除
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Dashboard;
