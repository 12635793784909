import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || '').trim();

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/api/auth/password/reset/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage('パスワードリセットのリンクを送信しました。');
      } else {
        setMessage('エラーが発生しました。もう一度お試しください。');
      }
    } catch (error) {
      setMessage('サーバーエラーが発生しました。');
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        パスワードリセット
      </Typography>
      <form onSubmit={handlePasswordReset}>
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          リセットリンクを送信
        </Button>
      </form>
      {message && (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '16px' }}>
          {message}
        </Typography>
      )}
    </Container>
  );
};

export default PasswordReset;
