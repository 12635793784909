import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Alert,
  Container,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { apiRequestWithAuth } from "../utils/auth";
import { useNavigate } from "react-router-dom";

const Subscription: React.FC = () => {
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || "").trim();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<
    string | null | React.ReactNode
  >(null);
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "error" | "info" | "warning"
  >("info");

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await apiRequestWithAuth(
          `${BASE_URL}/api/v1/user-info/`,
          { method: "GET" },
          navigate
        );
        if (data.error) {
          setAlertMessage(data.error || "ユーザー情報の取得に失敗しました");
          setAlertSeverity("error");
        } else {
          setUserInfo(data);
        }
      } catch (error) {
        setAlertMessage("サーバーエラーが発生しました。");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [BASE_URL, navigate]);

  const handleCheckout = async () => {
    try {
      const data = await apiRequestWithAuth(
        `${BASE_URL}/api/v1/create-payment-link/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ plan: "basic" }), // 必要に応じて適切なデータを指定
        },
        navigate
      );
      if (data.url) {
        window.location.href = data.url; // Payment Linkにリダイレクト
      } else {
        setAlertMessage(
          <>
            Payment Linkの取得に失敗しました。詳細は
            <a
              href="https://tokimoa.jp/contact/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              こちらのフォーム
            </a>
            よりお問い合わせください。
          </>
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMessage(
        <>
          Payment Link生成中にエラーが発生しました。詳細は
          <a
            href="https://tokimoa.jp/contact/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            こちらのフォーム
          </a>
          よりお問い合わせください。
        </>
      );
      setAlertSeverity("error");
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const data = await apiRequestWithAuth(
        `${BASE_URL}/api/v1/cancel-subscription/`,
        { method: "POST" },
        navigate
      );
      if (!data.error) {
        setAlertMessage(
          "サブスクリプションが解約されました。ご利用ありがとうございました。"
        );
        setAlertSeverity("success");
        setUserInfo({ ...userInfo, plan: "free" });
      } else {
        setAlertMessage(
          <>
            解約に失敗しました。詳細は
            <a
              href="https://tokimoa.jp/contact/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff", textDecoration: "underline" }}
            >
              こちらのフォーム
            </a>
            よりお問い合わせください。
          </>
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMessage(
        <>
          解約処理中にエラーが発生しました。詳細は
          <a
            href="https://tokimoa.jp/contact/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            こちらのフォーム
          </a>
          よりお問い合わせください。
        </>
      );
      setAlertSeverity("error");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        サブスクリプション管理
      </Typography>

      {alertMessage && (
        <Alert
          severity={alertSeverity}
          sx={{ mb: 2 }}
          onClose={() => setAlertMessage(null)}
        >
          {alertMessage}
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          現在のプラン: {userInfo?.plan || "未登録"}
        </Typography>
        <Typography>
          アップロード制限: {userInfo?.video_upload_count_today || 0} /{" "}
          {userInfo?.video_upload_limit || "無制限"}
        </Typography>
      </Box>

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>機能</TableCell>
              <TableCell align="center">Free</TableCell>
              <TableCell align="center">Basic</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>アップロード制限</TableCell>
              <TableCell align="center">3回/日</TableCell>
              <TableCell align="center">無制限</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PNGダウンロード</TableCell>
              <TableCell align="center">可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PDFダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>xlsxダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>xlsxダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>svgダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Figma用ファイルダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>rowデータダウンロード</TableCell>
              <TableCell align="center">不可</TableCell>
              <TableCell align="center">可</TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: "#f9f9f9",
                borderTop: "2px solid #e0e0e0",
              }}
            >
              <TableCell
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "#495057",
                }}
              >
                価格
              </TableCell>
              <TableCell align="center" sx={{ fontSize: "1rem" }}>
                無料
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#007bff",
                }}
              >
                1,100円/30日
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="center" gap={2}>
        <Button variant="contained" color="primary" disabled={!userInfo || userInfo.plan === "basic"} onClick={handleCheckout}>
          サブスクリプション登録
        </Button>
        <Button
          variant="outlined"
          color="error"
          disabled={!userInfo || userInfo.plan === "free"} // userInfoがnullまたはplanがFreeの場合無効化
          onClick={handleCancelSubscription}
        >
          サブスクリプション解約
        </Button>
      </Box>
    </Container>
  );
};

export default Subscription;
