import React from 'react';

const Settings: React.FC = () => {
    return (
        <div>
            <h2>Settings Page</h2>
        </div>
    );
};

export default Settings;
