import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";

const Home: React.FC = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        ようこそ！CutEdgeへ
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        CutEdgeは、「カット表」を自動生成するAIツールです。
        煩雑な手作業を効率化し、制作現場の効率化をお手伝いします。
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">1. 動画をアップロード</Typography>
          <Typography variant="body2" paragraph>
            アップロード画面から動画をアップロードしてください。AIが動画を解析し、カット表を自動で作成します。
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "0.8rem", color: "gray" }}>
            ※無料プランでは、1日に3本までのアップロード制限があります。
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">2. カット編集</Typography>
          <Typography variant="body2" paragraph>
            作成されたカット表をもとに、必要なシーンを追加、表の編集を行ってください。
            編集作業は一時保存も可能です。
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">3. 保存とエクスポート</Typography>
          <Typography variant="body2" paragraph>
            編集が完了したら、データを保存し、任意のファイルでダウンロードしてください。
            有料プランでは多様な形式（PDF、Excel、SVGなど）に対応しています。
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "0.8rem", color: "gray" }}>
            ※スマートフォンをご利用の場合、一部のエクスポート形式（PNG、PDF）は非対応です。
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">4. プランの選択</Typography>
          <Typography variant="body2" paragraph>
            無料プランに加えて、機能強化版の有料プランもご用意しています。最適なプランを選択してください。
            サブスクリプション管理から簡単に登録が可能です。
          </Typography>
          <Typography variant="caption" sx={{ fontSize: "0.8rem", color: "gray" }}>
            ※プランの詳細は「サブスクリプション管理」ページをご確認ください。
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
