import React, { useState } from 'react';
import { TextField, Button, Typography, Container, IconButton, InputAdornment } from '@mui/material'; // MUIから必要なコンポーネントをインポート
import { Visibility, VisibilityOff, CheckCircle } from '@mui/icons-material'; // アイコン用のインポート
import { useParams, useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';

const PasswordResetConfirm: React.FC = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate(); // リダイレクト用
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // パスワード表示切り替え
  const [message, setMessage] = useState<string[]>([]); // メッセージは配列
  const [isPasswordValid, setIsPasswordValid] = useState(false); // パスワード条件を満たしたか
  const BASE_URL = (process.env.REACT_APP_API_BASE_URL || '').trim();


  // パスワード条件チェック
  const validatePassword = (password: string) => {
    const lengthValid = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    return lengthValid && hasNumber && hasSymbol && hasLetter;
};
const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPass = e.target.value;
    setNewPassword(newPass); // 既存のonChange処理
    setIsPasswordValid(validatePassword(newPass)); // パスワードのバリデーション処理
  };

  const handlePasswordResetConfirm = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/api/auth/password/reset/confirm/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid,
          token,
          new_password1: newPassword,
          new_password2: confirmPassword,
        }),
      });

      if (response.ok) {
        setMessage(['パスワードがリセットされました。3秒後にログインページに移動します。']);
        setTimeout(() => {
          navigate('/login'); // 3秒後にログインページにリダイレクト
        }, 3000);
      }else {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        setMessage(errorData.non_field_errors || ['パスワードリセットに失敗しました。']);
      }
    } catch (error) {
      setMessage(['サーバーエラーが発生しました。時間を空けてから再度お試しください。']);
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        新しいパスワードの設定
      </Typography>
      <Typography gutterBottom>
        パスワードは半角英字、数字、記号を組み合わせた8文字以上で入力してください。
      </Typography>
      <form onSubmit={handlePasswordResetConfirm}>
        <TextField
          label="新しいパスワード"
          variant="outlined"
          margin="normal"
          type={showPassword ? 'text' : 'password'} // パスワード表示切り替え
          value={newPassword}
          onChange={handlePasswordChange}
          
          sx={{
            width: '100%',  // 幅を100%に設定
            maxWidth: 450,
            margin: '10 auto 0'  // 左右中央寄せ
        }}

        InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                {isPasswordValid && <CheckCircle sx={{ color: 'green', ml: 1 }} />} {/* パスワードが有効ならチェック表示 */}
              </InputAdornment>
            ),
          }}
        
        />
        <TextField
          label="新しいパスワード（確認）"
          variant="outlined"
          margin="normal"
          type={showPassword ? 'text' : 'password'} // パスワード表示切り替え
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            width: '100%',  // 幅を100%に設定
            maxWidth: 450,
            margin: '5 auto 0'  // 左右中央寄せ
        }}
        />
        <Button type="submit" variant="contained" color="primary" disabled={!isPasswordValid || newPassword !== confirmPassword} // 条件満たさないと無効化 
        sx={{
            width: '100%',  // 幅を100%に設定
            maxWidth: 450,
            margin: '10 auto 0'  // 左右中央寄せ
        }}>
          パスワードをリセット
        </Button>
      </form>
      {message.length > 0 && (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '16px', width: '100%', maxWidth: 450, whiteSpace: 'pre-line', color:'red'}}>
         {message.map((msg, index) => (
            <React.Fragment key={index}>
              {msg}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      )}
    </Container>
  );
};

export default PasswordResetConfirm;
