import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import {Box, TextField, Button, Typography, Container, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'; // アイコン用のインポート
import GoogleAuthButton from "./GoogleAuthButton";

interface LoginFormProps {
    onLogin: (email: string, password: string) => Promise<boolean>;
    onGoogleLogin: (token: string) => Promise<void>;  // 非同期関数に変更
  }

const Login: React.FC< LoginFormProps > = ({ onLogin, onGoogleLogin }) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false); // パスワード表示切り替え
    const [errorMessage, setErrorMessage] = useState<string[]>([]);

    // メールアドレスとパスワードによるログイン処理
    const handleLogin = async () => {
      if (email && password) {
        try {
          const isSuccess = await onLogin(email, password);  // booleanを受け取る
          if (isSuccess) {
            navigate('/');  // ログイン成功時にのみ遷移
          } else {
            setErrorMessage(["ログインに失敗しました"]);
          }
        } catch (error) {
          console.error("Login failed:", error);
          setErrorMessage(["ログインに失敗しました"]);
        }
      } else {
        setErrorMessage(["メールアドレスとパスワードを入力してください"]);
      }
    };
    
    

    const handlePasswordReset = () => {
        navigate('/password-reset'); 
      };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="h4" gutterBottom>ログイン</Typography>
            {errorMessage.length > 0 && (
        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '16px', width: '100%', maxWidth: 450, whiteSpace: 'pre-line', color:'red'}}>
         {errorMessage.map((msg, index) => (
            <React.Fragment key={index}>
              {msg}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      )}
            <TextField
                label="メールアドレス"
                variant="outlined"
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  width: '100%',  // 幅を100%に設定
                  maxWidth: 450,
                  margin: '0 auto'  // 左右中央寄せ
              }}
            />
            <TextField
                label="パスワード"
                variant="outlined"
                margin="normal"
                type={showPassword ? 'text' : 'password'} // パスワード表示切り替え
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  width: '100%',  // 幅を100%に設定
                  maxWidth: 450,
                  margin: '5 auto 0'  // 左右中央寄せ
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                sx={{ marginTop: 2}}
            >
                ログイン
            </Button>
            <Typography
                variant="body2"
                color="primary"
                align="center"
                style={{ cursor: 'pointer', marginTop: '16px' }}
                onClick={handlePasswordReset}
            >
                パスワードを忘れた方はこちら
            </Typography>
            <Typography variant="h6" sx={{ marginTop: 3, marginBottom: 3}}>または</Typography>
            <GoogleAuthButton onLoginSuccess={onGoogleLogin} isRegister={false} />

        </Box>
    );
};

export default Login;